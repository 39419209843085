body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.nav-link {
  position: absolute;
  top: 0;
  margin-top: 32px;
  font-size: 20px;
}

.nav-link:hover {
  color: #000;
}

#bg {
  background: hsla(199, 100%, 83%, 1);

  background: radial-gradient(
    circle,
    hsla(199, 100%, 83%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(199, 100%, 83%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(199, 100%, 83%, 1) 0%,
    hsla(0, 0%, 100%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#A9E4FF", endColorstr="#FFFFFF", GradientType=1 );
}

.ffxi-font {
  font-family: "Averia Serif Libre", cursive;
}

/* #myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
} */

.selected {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset,
    0px 0px 30px rgba(0, 145, 255, 0.6);
}

.resultcard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  max-width: 30em;
  border: 3px solid black;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin: 2px;
  border-radius: 10px;
  background-color: white;
}

.titlecard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  max-width: 40em;
  border: 3px solid black;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  padding: 10px;
  margin: 2px;
  border-radius: 10px;
  background-color: white;
}

.jobtile {
  min-width: 10em;
  min-height: 10em;
  border: 3px solid black;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 5px;
  border-radius: 10px;
  cursor: move;
  background-color: white;
}

.characterselect {
  min-width: 15em;
  min-height: 27em;
  max-width: 20em;
  border: 3px solid black;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  margin: 5px;
  background-color: whitesmoke;
}

.dropzone {
  min-width: 8em;
  min-height: 20em;
  max-width: 20em;
  /* border: 3px solid black; */
  align-items: center;
  justify-content: center;
  /* padding: 1rem;
  border-radius: 10px;
  margin: 5px; */
  /* background-color: whitesmoke; */
}

.dragging {
  opacity: 0.5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.chat__app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.app__chatContainer {
  flex: 1;
  padding: 30px;
  overflow: auto;
}

.app__message {
  margin: 13px 0;
}

.app__message--even {
  /* background: #d6c8c8;
  color: black; */
  /* background-image: "./crystal.p"; */
}

.app__message--odd {
  /* background: #37a1d2; 
  color: black; */
  font-style: italic;
  padding-bottom: 15px;
}

.app__form {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid lightgray;
  box-shadow: 5px 10px 20px black;
}

.app__form > input {
  flex: 1;
  padding: 10px;
  border: none;
}

.app__form > button {
  padding: 10px;
}

.app__loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 5px;
}
